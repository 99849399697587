import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const Feedback = (props: any) => {
  const [feedback, setFeedback] = useState({});
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.closeModal()
  };
  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} id='feedbackform'>
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              Can you tell us about a time when information from our dashboard facilitated business
              decisions?
            </p>
            <textarea
              required
              className="w-100 form-control"
              name="feedback1"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, firstFeedback: event.target.value }))
              }></textarea>
          </div>
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              What one feature would you add or chnage on our dashboard? Please explain why.
            </p>
            <textarea
              required
              className="w-100 form-control"
              name="feedback2"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, secondFeedback: event.target.value }))
              }></textarea>
          </div>
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              Have you faced any challenges when using our dashboard? How can we make it better for
              you?
            </p>
            <textarea
              required
              className="w-100 form-control"
              name="feedback3"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, thirdFeedback: event.target.value }))
              }></textarea>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-5">
              <button type="submit" className="btn btn-primary uniBtnPurple">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Feedback;

import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { fetchLeaderboardData } from '../service/apiServices';
import { useAzureData } from './AzureContext';
type LeaderBoardContextType = {
  leaderboardData: any;

  errorMessage: any;
  isLoading: boolean;
};
type LeaderBoardContextProviderProps = {
  children: ReactNode;
};
export const LeaderBoardContext = createContext<LeaderBoardContextType | null>(null);
export const LeaderboardContextProvider = ({ children }: LeaderBoardContextProviderProps) => {
  const [leaderboardData, setLeaderboardData] = useState<any | null>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { apiToken } = useAzureData();

  const getLeaderBoardData = async () => {
    try {
      const response = await fetchLeaderboardData(apiToken);
      setLeaderboardData(response);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => getLeaderBoardData(), 1000);
    return () => {
      clearTimeout(timer);
    };
    
  }, []);

  const contextData = useMemo(
    () => ({ leaderboardData, errorMessage, isLoading }),
    [apiToken, isLoading]
  );
  return <LeaderBoardContext.Provider value={contextData}>{children}</LeaderBoardContext.Provider>;
};
export const useLeaderBoardContext = (): LeaderBoardContextType => {
  const context = useContext(LeaderBoardContext);
  if (context === null) {
    throw new Error('useLeaderBoardContext must be used within a DataProvider');
  }
  return context;
};

import { useAzureData } from '../context/AzureContext';

const useLoading = () => {
    const { isLoading: secondLoading } = useAzureData();
  

  return secondLoading
};

export default useLoading;

import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useAzureData } from './AzureContext';
import { fetchAllModels } from '../service/apiServices';
type PromptPlaygroundContextType = {
  models: Model[] | null;
  error: Error | any | null;
  isLoading: boolean;
};
type Model = {
  model_id: string;
  model_name: string;
  client_id: string;
};

type PromptPlaygroundContextProviderProps = {
  children: ReactNode;
};
export const PromptPlaygroundContext = createContext<PromptPlaygroundContextType | null>(null);
export const PromptPlaygroundContextProvider = ({
  children
}: PromptPlaygroundContextProviderProps) => {
  const [models, setModels] = useState<Model[] | null>(null);
  const { apiToken } = useAzureData();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const getAllModels = async () => {
    try {
      const result = await fetchAllModels(apiToken);
      setModels(result);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => getAllModels(), 1000);
    return () => clearTimeout(timer);
  }, []);
  const promptPlaygrounddata = useMemo(() => ({ models, error, isLoading }), [apiToken, isLoading]);
  return (
    <PromptPlaygroundContext.Provider value={promptPlaygrounddata}>
      {children}
    </PromptPlaygroundContext.Provider>
  );
};
export const usePromptPlaygroundContext = () => {
  const context = useContext(PromptPlaygroundContext);
  if (context === null) {
    throw new Error('usePromptPlaygroundContext must be used within a DataProvider');
  }
  return context;
};

import { ChartOptions } from "chart.js";
import { unileverColor } from "./constants";
import * as XLSX from 'xlsx';

export const sumOfCost = (arr: number[]) => {

  const sum = arr.reduce((acc: number, curr: number) => { return acc + curr }, 0).toFixed(2)

  return sum
}
const isallElementsNull = (arr: (number | string | null)[]) => {
  for (let element of arr) {
    if (element !== null) {
      return false;
    }
  }
  return true;
}

export const isValidArray = (arr: any) => {
  return arr !== null && arr.length > 0 && !isallElementsNull(arr);
}

export const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  notation: 'compact',
});
export const createHeaders = (token: any) => {
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json')
    return headers;
  }
};
export const optionsForLineGraph = (text: string,xlabel?:string,ylabel?:string) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const
      },
      title: {
        display: true,
        text
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(0,0,0,0.1)',
          borderDash: [2, 2]
        },
        title:{
          display:true,
          text:xlabel
        }
      },
      y: {
        grid: {
          display: true,
          color: 'rgba(0,0,0,0.1)',
          borderDash: [2, 2]
        },
        title:{
          display:true,
          text:ylabel
        }
      }
    }
  };
};
export const dataForLineGraph = (xDataSet: number[], yDataSet: number[],dataSetLabel?:string) => {
  return {
    labels: xDataSet,
    datasets: [
      {
        fill: false,
        label: dataSetLabel,
        data: yDataSet,
        borderColor: unileverColor['blue'].rgb,
        backgroundColor: unileverColor['blue'].rgb,
      }
    ]
  };
};

export const optionsForBarGraph = (textVal: string): ChartOptions<'bar'> => {
  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: textVal,
      },
      legend: {
        display: true,
        position: 'right'
      },
    }
  };
};


export const dataForBarGraph = (xDataset: any[], yDataSet: any[]) => {
  return {
    labels: yDataSet,
    datasets:xDataset
  }

};

export const optionsForPieChart = (text: string): ChartOptions<'pie'> => {
  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text
      },
      legend: {
        position: 'right'
      }
    }
  };
};

export const dataForPieChart = (xDataSet: any[], yDataSet: string[]) => {
  const colors = Object.values(unileverColor).map(color => color.rgb)
  return {
    labels: yDataSet,
    datasets: [
      {
        fill: true,
        label: 'Percentage',
        data: xDataSet,
        backgroundColor: colors,
        borderwidth: 1
      }
    ]
  };
};

export   const downloadExcel = (appCost:any) => {
  if (!appCost?.records || appCost?.records.length === 0) {
    return;
  }
  //creating a new workbook
  const workbook = XLSX.utils.book_new();

  //conerting the data to worksheet
  const worksheet = XLSX.utils.json_to_sheet(appCost?.records);

  //appending the worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //generating binary string repreentation of the workbook
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

  //function to convert binary string to blob
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  //creating bob from binary string
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'table_data.xlsx';
  document.body.appendChild(link);
  link.click();
};
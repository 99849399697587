
import { envVariables } from '../utilities/constants';

const configureClientId = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'aiflmdev.unilever.com':
    case 'bnlwe-ai04-d-930633-webapi-02.azurewebsites.net':
      return envVariables.dev.clientId;
    case 'aiflmuat.unilever.com':
    case 'bnlwe-ai03-q-931039-webapi-02.azurewebsites.net':
      return envVariables.qa.clientId;
    case 'aiflmprod.unilever.com':
    case 'bnlwe-ai01-p-930632-webapi-02.azurewebsites.net':
      return envVariables.prod.clientId;
    default:
      return envVariables.dev.clientId;
  }
};

const configureBaseUrl = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'localhost':
    case 'aiflmdev.unilever.com':
    case 'bnlwe-ai04-d-930633-webapi-02.azurewebsites.net':
      return envVariables.dev.apiBaseUrl;
    case 'aiflmuat.unilever.com':
    case 'bnlwe-ai03-q-931039-webapi-02.azurewebsites.net':
      return envVariables.qa.apiBaseUrl;
    case 'aiflmprod.unilever.com':
    case 'bnlwe-ai01-p-930632-webapi-02.azurewebsites.net':
      return envVariables.prod.apiBaseUrl;
    default:
      return envVariables.dev.apiBaseUrl;
  }
};

export const appConfig = {
  auth: {
    clientId: configureClientId(),
    tenantId: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
    authority: 'https://login.microsoftonline.com/unilever.onmicrosoft.com',
    redirectUri: '/'
  },
  api: {
    baseUrl: configureBaseUrl()
  }
};

import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useAzureData } from './AzureContext';
import {
  fetchApplicationsData,
  fetchKPIS,
  fetchModelStatus,
  fetchModelUsage
} from '../service/apiServices';

type DashboardContextType = {
  applicationList: App[] | null;
  modelUsage: ModelUsage[];
  cardData: KPIs | null;
  modelStatus: ModelStatus[];
  isLoading: boolean;


};
type DashboardContextProviderProps = {
  children: ReactNode;
};
type KPIs = {
  application: string;
  model: string;
  avg_execution_time: string;
  no_of_users: string;
  total_no_of_requests: string;
  no_of_successful_responses: string;
  no_of_tokens: string;
};
type ModelUsage = {
  model_id: string;
  model_name: string;
  percentage_usage: number;
};
type ModelStatus = {
  model_id: string;
  model_name: string;
  security_clearance: string;
  IT_clearance: string;
  legal_clearance: string;
};

type App = {
  application_id: number;
  application_name: string;
};
export const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardContextProvider = ({ children }: DashboardContextProviderProps) => {
  const [applicationList, setApplicationList] = useState<App[] | null>(null); //applications
  const [modelUsage, setModelUsage] = useState<ModelUsage[]>([]);
  const [cardData, setCardData] = useState<KPIs | null>(null);
  const [modelStatus, setmodelStatus] = useState<ModelStatus[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { apiToken } = useAzureData();
  const getDashboardData = async () => {
    try {
      const modelUsageResponse = await fetchModelUsage(apiToken);
      setModelUsage(modelUsageResponse);
      const kpiResponse = await fetchKPIS({ appID: undefined, modelID: undefined }, apiToken);
      setCardData(kpiResponse);
      const modelStatusResponse = await fetchModelStatus(apiToken);
      setmodelStatus(modelStatusResponse.models);
      const applicationResponse = await fetchApplicationsData(apiToken);
      setApplicationList(applicationResponse);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => getDashboardData(), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const dashBoardData = useMemo(
    () => ({ applicationList, modelStatus, modelUsage, cardData, error, isLoading }),
    [apiToken, isLoading]
  );
  return <DashboardContext.Provider value={dashBoardData}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);
  if (context === null) {
    throw new Error('useDashboardContext must be used within a DataProvider');
  }
  return context;
};

import { useEffect, useRef, useState } from 'react';
import { Pagination, Modal } from 'react-bootstrap';
import { useAzureData } from '../context/AzureContext';
import {
  createPrompt,
  fetchAllPrompt,
  fetchModelsData,
  fetchPromptModels,
  postUserQuery
} from '../service/apiServices';
import PromptLibraryDetails from './PromptLibraryDetails';
import { errorMessages } from '../utilities/constants';
import PlaceHolder from './PlaceHolder';
import './PromptLibrary.css';
import parse from 'html-react-parser';
import { usePromptLibraryContext } from '../context/PromptLibraryContext';
import Dialog from './Dialog';
type PromptInfo = {
  id: string;
  version: any;
  prompt_id: string;
  app_name: string;
  llm_tested_with: string;
  description: string;
  category: string;
  creation_date: string;
  last_modified_date: string;
  usage_examples: string;
  author: string;
};
const PromptLibrary = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    const timer = setTimeout(() => setShow(false), 500);
    return () => clearTimeout(timer);
  };
  const handleShow = () => setShow(true);
  const { apiToken } = useAzureData();
  const [showDropdown, setShowDropdown] = useState(false);

  const [searchResults, setSearchResults] = useState<PromptInfo[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardId, setCardId] = useState(null);
  const [showChild, setShowChild] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [selectedApp, setSelectedApp] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelData, setModelData] = useState<any[] | null>(null);
  const [category, setCategory] = useState('');
  const [usageExamples, setUsageExamples] = useState('');
  const [description, setDescription] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [promptDetails, setPromptDetails] = useState<any | null>(null);
  const [disabledVal, setDisabled] = useState<any | null>(null);
  const { graphData } = useAzureData();
  const { applicationList, prompts, error, isLoading } = usePromptLibraryContext();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSearchResults(prompts.reverse());
  }, [prompts]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const result = await fetchPromptModels(selectedApp, apiToken);
        setModelData(result);
        if (selectedId && promptDetails) {
          let modelObj = result?.find(
            (obj: { model_name: any }) => obj.model_name === promptDetails?.llm_tested_with
          );
          if (modelObj) {
            setSelectedModel(modelObj.model_id);
          }
        }
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    if (selectedApp && selectedApp !== 'ALL') {
      fetchModels();
    }
  }, [selectedApp]);

  const handleClickOutside = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node))
      setShowDropdown(false);
  };
  const handleAppChange = async (id: any) => {
    setSelectedApp(id);
    setModelData(null);
    setSelectedModel(null);
    if (id !== 'ALL') {
      const select_model = document.getElementById('modelselectbox') as HTMLSelectElement;
      if (select_model !== null) {
        select_model.selectedIndex = 0;
      }
    }
  };
  const handleModelChange = async (id: any) => {
    setSelectedModel(id);
  };
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setDisabled(true);
    const promptInfo = {
      app_id: selectedApp,
      model_id: selectedModel,
      category: category,
      description: description,
      usage_examples: usageExamples
    };
    const submitPromptData = async () => {
      try {
        await createPrompt(promptInfo, apiToken);
        setSuccessMsg('Prompt created successfully');
        setCategory('');
        setDescription('');
        setUsageExamples('');
        setDisabled(false);
        const timer = setTimeout(() => {
          setSuccessMsg('');
        }, 1000);

        return () => clearTimeout(timer);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    submitPromptData();
    handleClose();
    const timer = setTimeout(async () => {
      const newprompts = await fetchAllPrompt(apiToken);
      setSearchResults(newprompts.reverse());
    }, 1000);
    return () => clearTimeout(timer);
  };
  const handleUpdate = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setDisabled(true);
    const promptInfo = {
      prompt_id: selectedId,
      app_id: selectedApp,
      model_id: selectedModel,
      category: category,
      description: description,
      usage_examples: usageExamples
    };

    const submitPromptData = async () => {
      try {
        await createPrompt(promptInfo, apiToken);
        setSuccessMsg('Prompt updated successfully');
        setDisabled(false);
        const timer = setTimeout(() => {
          setSuccessMsg('');
        }, 1000);

        return () => clearTimeout(timer);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    submitPromptData();
    handleClose();
    const timer = setTimeout(async () => {
      const newprompts = await fetchAllPrompt(apiToken);
      setSearchResults(newprompts.reverse());
    }, 1000);
    return () => clearTimeout(timer);
  };
  const handleClick = (id: any) => {
    setShowChild(true);
    setCardId(id);
  };

  const handleBackClick = () => {
    setShowChild(false);
    setCardId(null);
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e: any) => {
    setUserInput(e.target.value);
    if (e.target.value === '') {
      setCurrentPage(1);
      setSearchResults(prompts);
    }
  };

  const setSinglePrompt = (promptInfo: any) => {
    setPromptDetails(promptInfo);
    setSelectedId(promptInfo.prompt_id);
    setCategory(promptInfo.category);
    setUsageExamples(promptInfo.usage_examples);
    setDescription(promptInfo.description);
    let appObj = applicationList?.find((app) => app.application_name === promptInfo.app_name);
    let modelObj = modelData?.find((model) => model.model_name === promptInfo.llm_tested_with);
    if (appObj) {
      setSelectedApp(appObj.application_id);
    }
    if (modelObj) setSelectedModel(modelObj.model_id);
  };
  const clearSetValue = () => {
    setPromptDetails(null);
    setSelectedId('');
    setCategory('');
    setUsageExamples('');
    setDescription('');
    setSelectedApp('');
    setSelectedModel(null);
    setModelData(null);
  };

  const handleSearch = async () => {
    setCurrentPage(1);
    if (userInput !== '') {
      const userQuery = {
        app_name: userInput
      };
      try {
        const response = await postUserQuery(userQuery, apiToken);
        setSearchResults(response.reverse());
      } catch (error: any) {
        setErrorMessage(error);
      }
    } else return;
  };
  const cardsPerPage = 6;
  let totalPages: number | undefined;
  let currentCards: PromptInfo[] | null = null;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const calculateTotalPages = (results: any, itemsPerPage: any) => {
    return Math.ceil(results.length / itemsPerPage);
  };
  const getCurrentCards = (results: PromptInfo[], startIndex: number, endIndex: number) => {
    return results.slice(startIndex, endIndex);
  };
  if (searchResults) {
    totalPages = calculateTotalPages(searchResults, cardsPerPage);
    currentCards = getCurrentCards(searchResults, indexOfFirstCard, indexOfLastCard);
  }
  const renderPrompts = () => {
    if (currentCards && currentCards.length > 0) {
      return currentCards.map((promptInfo) => (
        <div className="col-md-4" key={promptInfo.id}>
          <div className="card small-box rounded">
            <div className="card-header py-2 border-bottom-0">
              <h6
                onClick={() => handleClick(promptInfo.prompt_id)}
                className="my-0 prompt-library-card-heading"
                style={{ cursor: 'pointer', display: 'inline-block', width: '70%' }}>
                {promptInfo.app_name}
              </h6>
              <span style={{ marginRight: '15px' }}>Version: {promptInfo.version}</span>
              {promptInfo.author === graphData?.userPrincipalName && (
                <span
                  className="prompt-library-card-edit"
                  onClick={() => {
                    setSinglePrompt(promptInfo);
                    handleShow();
                  }}
                  style={{ cursor: 'pointer' }}>
                  Edit
                </span>
              )}
            </div>
            <div className="card-body">{promptInfo.description}</div>
          </div>
        </div>
      ));
    } else if (currentCards && currentCards.length === 0) {
      return (
        <>
          <p>No Prompt Templates Found</p>
        </>
      );
    }
  };

  const listOfApplication = prompts?.map((prompt) => prompt.app_name);
  const uniqueListOfApp = [...new Set(listOfApplication)].filter((appname) =>
    appname.toLowerCase().includes(userInput.toLowerCase())
  );

  const renderDropdownSearch = () => {
    return (
      <>
        <div className="dropdown" ref={dropdownRef}>
          <input
            type="search"
            value={userInput}
            style={{ borderRadius: '8px' }}
            onChange={(e) => handleInputChange(e)}
            onClick={() => setShowDropdown(true)}
            placeholder="Search by App"
          />
          {showDropdown && (
            <div className="dropdown-content">
              {uniqueListOfApp?.map((item: string, index: number) => (
                <a
                  key={item}
                  onClick={() => {
                    setUserInput(item);
                    setShowDropdown(false);
                  }}>
                  {item}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="px-4">
          <button className="btn btn-primary uniBtnPurple" onClick={handleSearch}>
            Search
          </button>
        </div>
      </>
    );
  };
  if (errorMessage || error)
    if (errorMessage.message === 'UNAUTHORIZED' || errorMessage === 'UNAUTHORIZED')
      return <Dialog />;
    else {
      return <PlaceHolder message={parse(errorMessages.serverError)} />;
    }

  if (isLoading)
    return (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );
  else {
    return (
      <>
        {showChild ? (
          <PromptLibraryDetails promptID={cardId} onBackClick={handleBackClick} />
        ) : (
          <>
            <div className="card mt-4 prompt-library">
              <div className="card-body">
                <div className="row mt-4 mb-3">
                  <div className="col-md-5 mx-auto d-flex flex-nowrap">
                    {uniqueListOfApp && renderDropdownSearch()}

                    <div className="col-md-4">
                      <button
                        className="btn btn-primary uniBtnPurple"
                        onClick={() => {
                          clearSetValue();
                          handleShow();
                        }}>
                        Create Prompt
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row g-2 pt-4 mb-3 text-left">{renderPrompts()}</div>

                <div className="row mb-3">
                  <div className="d-flex justify-content-center">
                    <Pagination>
                      <Pagination.First
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}>
                        Prev
                      </Pagination.Prev>
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <Pagination.Item
                            key={pageNumber}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                          </Pagination.Item>
                        );
                      })}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}>
                        Next
                      </Pagination.Next>
                      <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Please {selectedId ? 'update' : 'enter'} Prompt details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {successMsg && (
                  <div className="text-center" style={{ color: '#008651' }}>
                    {successMsg}
                  </div>
                )}
                <form onSubmit={selectedId ? handleUpdate : handleSubmit}>
                  <div className="mb-3">
                    <p className="mb-1 unl-label-p">Select Application</p>
                    <select
                      required
                      value={selectedApp ?? ''}
                      name="app_name"
                      className="form-select"
                      style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
                      onChange={(e) => handleAppChange(e.target.value)}>
                      <option value="">Select Application</option>
                      {applicationList?.map((data) => (
                        <option key={data.application_id} value={data.application_id}>
                          {data.application_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <p className="mb-1 unl-label-p">LLM Tested with</p>
                    <select
                      required
                      value={selectedModel ?? ''}
                      name="llm_tested_with"
                      className="form-select"
                      style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
                      onChange={(e) => handleModelChange(e.target.value)}
                      id="modelselectbox">
                      <option value="">Select Model</option>
                      {modelData?.map((model) => (
                        <option key={model.model_id} value={model.model_id}>
                          {model.model_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <p className="mb-1 unl-label-p">Description</p>
                    <textarea
                      required
                      className="w-100 form-control"
                      name="description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}></textarea>
                  </div>
                  <div className="mb-3">
                    <p className="mb-1 unl-label-p">Category</p>
                    <input
                      required
                      className="w-100 form-control"
                      name="category"
                      value={category}
                      onChange={(event) => setCategory(event.target.value)}></input>
                  </div>
                  <div className="mb-3">
                    <p className="mb-1 unl-label-p">Usage Example</p>
                    <textarea
                      required
                      className="w-100 form-control"
                      name="usage_examples"
                      value={usageExamples}
                      onChange={(event) => setUsageExamples(event.target.value)}></textarea>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-5">
                      <button
                        type="submit"
                        className="btn btn-primary uniBtnPurple"
                        disabled={disabledVal}>
                        {selectedId ? 'Update' : 'Create'} Prompt
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
};

export default PromptLibrary;

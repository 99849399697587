// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container-cost{
    max-height: 28.125rem;
    overflow-y: auto;
}


thead th{
    position: sticky;
    top:0;
 
    background-color: #f1f1f1;
}
.date-selector{
    min-width: 17.5rem;
    
  }


tfoot {
    position: sticky;
    bottom: 0;
    background-color: #f1f1f1;
    
}
tfoot td {
    border-top: 2px solid #ddd;
}


.container{
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

@media (max-width:600px) {
    .button-container{
       height: 50%;
        
       
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/FinOps.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;IAChB,KAAK;;IAEL,yBAAyB;AAC7B;AACA;IACI,kBAAkB;;EAEpB;;;AAGF;IACI,gBAAgB;IAChB,SAAS;IACT,yBAAyB;;AAE7B;AACA;IACI,0BAA0B;AAC9B;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;;AAGvB;;AAEA;IACI;OACG,WAAW;;;IAGd;AACJ","sourcesContent":[".table-container-cost{\n    max-height: 28.125rem;\n    overflow-y: auto;\n}\n\n\nthead th{\n    position: sticky;\n    top:0;\n \n    background-color: #f1f1f1;\n}\n.date-selector{\n    min-width: 17.5rem;\n    \n  }\n\n\ntfoot {\n    position: sticky;\n    bottom: 0;\n    background-color: #f1f1f1;\n    \n}\ntfoot td {\n    border-top: 2px solid #ddd;\n}\n\n\n.container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n   \n}\n\n@media (max-width:600px) {\n    .button-container{\n       height: 50%;\n        \n       \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

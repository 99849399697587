import Header from '../components/Header';
import Dashboard from '../components/Dashboard';
import Rai from '../components/Rai';
import Leaderboard from '../components/Leaderboard';
import PromptLibrary from '../components/PromptLibrary';
import PromptPlayground from '../components/PromptPlayground';
import Footer from '../components/Footer';
import useLoading from '../hooks/useLoading';
import FinOps from '../components/FinOps';
import ContextProvider from '../context/ContextProviders';

const AiFoundation = (props: any) => {
  const isLoading = useLoading();
  const renderPage = () => {
    switch (props.page) {
      case 'finops':
        return <FinOps />;
      case 'leaderboard':
        return <Leaderboard />;
      case 'promptlibrary':
        return <PromptLibrary />;
      case 'promptplayground':
        return <PromptPlayground />;
      case 'rai':
        return <Rai />;
      case 'dashboard':
      default:
        return <Dashboard />;
    }
  };
  if (isLoading)
    return (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );
  return (
    <>
      <div className="container-fluid p-0 d-flex flex-column min-vh-100">
        <Header></Header>
        <ContextProvider>
          <main className="px-md-4">{renderPage()}</main>
        </ContextProvider>
        <Footer></Footer>
      </div>
    </>
  );
};

export default AiFoundation;

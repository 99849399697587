import { useEffect, useState } from 'react';
import { Tab, Tabs, Table } from 'react-bootstrap';
import './Leaderboard.css';
import { fetchLeaderboardData } from '../service/apiServices';
import { useAzureData } from '../context/AzureContext';
import { errorMessages } from '../utilities/constants';
import PlaceHolder from './PlaceHolder';
import { DatatableWrapper, TableBody, TableHeader } from 'react-bs-datatable';
import parse from 'html-react-parser';

const Leaderboard = () => {
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState<any | null>(null);
  const [leaderboardRaiData, setLeaderboardRaiData] = useState<any | null>(null);
  const leaderboardRaiHeaders: any = [
    {
      prop: 'model_name',
      title: 'Model',
      isSortable: true
    },
    {
      prop: 'flesch_kincaid_grade',
      title: 'Flesch Kincaid Grade',
      isSortable: true
    },
    {
      prop: 'automated_readability_index',
      title: 'Automated Readability Index',
      isSortable: true
    },
    {
      prop: 'flesch_reading_ease',
      title: 'Flesch Reading Ease',
      isSortable: true
    },
    {
      prop: 'smog_index',
      title: 'Smog Index',
      isSortable: true
    },
    {
      prop: 'coleman_liau_index',
      title: 'Coleman-Liau Index',
      isSortable: true
    },
    {
      prop: 'dale_chall_readability_score',
      title: 'Dale-Chall Readability Score',
      isSortable: true
    },
    {
      prop: 'gunning_fog_score',
      title: 'Gunning Fog Score',
      isSortable: true
    },
    {
      prop: 'linsear_write_formula',
      title: 'Linsear Write Formula',
      isSortable: true
    },
    {
      prop: 'toxicity',
      title: 'Toxicity',
      isSortable: true
    },
    {
      prop: 'perplexity',
      title: 'Perplexity',
      isSortable: true
    },
    {
      prop: 'relevance_score',
      title: 'Relevance Score',
      isSortable: true
    }
  ];
  const { apiToken } = useAzureData();
  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        const response = await fetchLeaderboardData(apiToken);
        setLeaderboardData(response);
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLeaderboardDetails();
  }, []);
  useEffect(() => {
    let modifiedRaiData: any = [];
    if (leaderboardData?.length > 0 && leaderboardData[0].LB_RAI) {
      leaderboardData[0].LB_RAI.forEach((row: any, index: number) => {
        (Object.keys(row) as any[]).forEach((key, i) => {
          if (key !== 'id' && key !== 'model_name') {
            if (row[key] !== null) {
              row[key] = Number(parseFloat(row[key]).toFixed(2));
            } else {
              row[key] = 0;
            }
          }
        });
        modifiedRaiData[index] = row;
      });
      setLeaderboardRaiData(modifiedRaiData);
    }
  }, [leaderboardData]);
  if (errorMessage) return <PlaceHolder message={parse(errorMessage.message === 'UNAUTHORIZED' || errorMessage === 'UNAUTHORIZED'?errorMessages.tokenExpired:errorMessages.serverError)} />;
  else if (isLoading)
    return (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );
  return (
    <div className="row pt-4 pb-2 mb-3  text-left leaderboard">
      <Tabs defaultActiveKey="Responsible AI" className="mb-3 rounded-3">
        {/* <Tab eventKey="Latency" title="Latency" className="table-responsive">
          <table className="table unl-table">
            <thead>
              <tr className="text-center">
                <th>Model</th>
                <th>Time Per Output Token (TPOT)</th>
                <th>Time To First Token (TTFT)</th>
                <th>Throughput</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData?.length>0 &&
                leaderboardData[0].LB_latency.map((item: any) => (
                  <tr key={item.id} className="text-nowrap text-center">
                    <td>{item.model_name}</td>
                    <td>{item.tpot}s</td>
                    <td>{item.ttft}s</td>
                    <td>{item.throughput}s</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Tab> */}
        <Tab eventKey="Responsible AI" title="Responsible AI" className="table-responsive rounded-3">
          {leaderboardRaiData && (
            <DatatableWrapper body={leaderboardRaiData} headers={leaderboardRaiHeaders}>
              <Table className="unl-table rounded-3">
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Leaderboard;

import { useEffect, useState } from 'react';

import { Graph } from './Graph';
import './Dashboard.css';
import { fetchGraphDetails, fetchKPIS, fetchModelsData } from '../service/apiServices';
import { formatter } from '../utilities/helper';
import { useAzureData } from '../context/AzureContext';
import { errorMessages } from '../utilities/constants';
import PlaceHolder from './PlaceHolder';
import parse from 'html-react-parser';
import { useDashboardContext } from '../context/DashboardContext';
import Dialog from './Dialog';

type Model = {
  model_id: string;
  model_name: string;
  client_id: string;
};
type KPIs = {
  application: string;
  model: string;
  avg_execution_time: string;
  no_of_users: string;
  total_no_of_requests: string;
  no_of_successful_responses: string;
  no_of_tokens: string;
};
type usagePercentage = {
  metric_name: string;
  value: number[];
  timestamp: string[];
  endpoint_name: string;
  workspaceId: string;
  servedModelName: string;
};
type GraphMetrics = {
  model_name: string;
  cpu_usage_percentage: usagePercentage[];
  mem_usage_percentage: usagePercentage[];
};
const Dashboard = () => {
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [modelData, setModelData] = useState<Model[] | null>(null);
  const [isGraphView, setisGraphView] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [KPIs, setKPIs] = useState<KPIs | null>(null);
  const [graphDetails, setGraphDetails] = useState<GraphMetrics | null>(null);
  const { apiToken } = useAzureData();
  const { applicationList, modelStatus, modelUsage, cardData, isLoading } = useDashboardContext();

  const fetchGraphByApp = async () => {
    try {
      const response = await fetchGraphDetails({ modelID: undefined }, apiToken);
      setGraphDetails(response);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  const fetchCardData = async () => {
    try {
      const response = await fetchKPIS({ appID: undefined, modelID: undefined }, apiToken);
      setKPIs(response);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  const fetchCardDataByApp = async () => {
    try {
      const response = await fetchKPIS({ appID: selectedApp, modelID: undefined }, apiToken);
      setKPIs(response);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  useEffect(() => {
    if (!isLoading) setKPIs(cardData);
  }, [isLoading, cardData]);
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const result = await fetchModelsData(selectedApp, apiToken);
        setModelData(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };

    if (selectedApp && selectedApp !== 'ALL') {
      fetchModels();
      fetchCardDataByApp();
      fetchGraphByApp();
    } else if (selectedApp && selectedApp === 'ALL') {
      fetchCardData();
    }
  }, [selectedApp]);

  useEffect(() => {
    const fetchCardDataByModel = async () => {
      try {
        const result = await fetchKPIS({ appID: selectedApp, modelID: selectedModel }, apiToken);
        setKPIs(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    const fetchGraphByModel = async () => {
      try {
        const result = await fetchGraphDetails({ modelID: selectedModel }, apiToken);
        setGraphDetails(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };

    if (selectedModel && selectedModel !== 'ALL') {
      fetchCardDataByModel();
      fetchGraphByModel();
    } else if (selectedModel && selectedModel === 'ALL') {
      fetchGraphByApp();
      fetchCardDataByApp();
    }
  }, [selectedModel]);

  const handleAppChange = async (id: any) => {
    setSelectedApp(id);
    setSelectedModel(null);
    if (id === 'ALL') {
      setisGraphView(false);
    } else {
      const select_model = document.getElementById('modelselectbox') as HTMLSelectElement;
      if (select_model !== null) {
        select_model.selectedIndex = 0;
      }
      setisGraphView(true);
    }
  };
  const handleModelChange = async (id: any) => {
    setSelectedModel(id);
  };

  const renderGraphs = () =>
    graphDetails ? (
      <>
        {graphDetails['cpu_usage_percentage'].map((metric, index) => (
          <span key={metric['metric_name']}>
            <Graph
              graphType="line"
              labelText={'CPU Usage (%)'}
              xData={metric['timestamp']}
              yData={metric['value']}
            />
          </span>
        ))}
        {graphDetails['mem_usage_percentage'].map((metric, index) => (
          <span key={metric['metric_name']}>
            <Graph
              graphType="line"
              labelText={'Memory Usage (%)'}
              xData={metric['timestamp']}
              yData={metric['value']}
            />
          </span>
        ))}
      </>
    ) : (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );

  const getStyleClass = (value: number) => {
    if (value >= 75 && value <= 100) {
      return '#008651';
    } else if (value >= 50 && value < 75) return '#00B190';
    else if (value >= 25 && value < 50) return '#00D7C4';
    else if (value < 25) return '#A3E1D2';
  };

  const renderTopAIModelsTable = () => {
    let serialNumber = 0;
    return (
      <>
        <div className="col">
          <div className="card mb-2  shadow-sm rounded-3">
            <div className="card-header pt-3">
              <h6 className="my-0 ">Top Gen AI Models by Usage</h6>
            </div>
            {modelUsage && (
              <div className="card-body table-responsive">
                <div className="table-container-usage">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '0.625rem' }}>#</th>
                        <th>Name</th>
                        <th>Popularity</th>
                        <th style={{ width: '2.25rem' }}>Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modelUsage?.map((model) => (
                        <tr key={model.model_id}>
                          <td>{++serialNumber}</td>
                          <td>{model.model_name}</td>
                          <td className="align-middle">
                            <div className="progress progress-xs">
                              <div
                                className={`progress-bar`}
                                style={{
                                  width: `${model.percentage_usage}%`,
                                  backgroundColor: getStyleClass(model.percentage_usage)
                                }}></div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <span
                              className={`badge badge-pill`}
                              style={{ backgroundColor: getStyleClass(model.percentage_usage) }}>
                              {model.percentage_usage}%
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderModelStatusTable = () => {
    let serialNumber = 0;
    return (
      <>
        <div className="col">
          <div className="card mb-2 shadow-sm rounded-3">
            <div className="card-header pt-3">
              <h6 className="my-0 ">All Models Status</h6>
            </div>
            {modelStatus.length > 0 && (
              <div className="card-body table-responsive">
                <div className="table-container-status">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '0.625rem' }}>#</th>
                        <th>Name</th>
                        <th>Security</th>
                        <th>IT</th>
                        <th>Legal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modelStatus?.map((data) => (
                        <tr key={data.model_id}>
                          <td>{++serialNumber}</td>
                          <td>{data.model_name}</td>
                          <td className="align-middle">
                            <span
                              className={`badge badge-pill show-empty-badge`}
                              style={{
                                backgroundColor:
                                  data.security_clearance?.toLowerCase() === 'yes'
                                    ? '#008651' // unilver branding green color
                                    : '#FF2828' //unilver branding red color
                              }}></span>
                          </td>
                          <td className="align-middle">
                            <span
                              className={`badge badge-pill ${
                                data.IT_clearance?.toLowerCase() === 'yes'
                                  ? 'bg-success'
                                  : 'bg-danger'
                              } show-empty-badge`}></span>
                          </td>
                          <td className="align-middle">
                            <span
                              className={`badge badge-pill ${
                                data.legal_clearance?.toLowerCase() === 'yes'
                                  ? 'bg-success'
                                  : 'bg-danger'
                              } show-empty-badge`}></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderTables = () => {
    return (
      <>
        {modelStatus && renderModelStatusTable()}
        {modelUsage && renderTopAIModelsTable()}
      </>
    );
  };

  const renderModelSelection = () => (
    <div className="mb-2 mb-md-0 float-end mx-2 form-group">
      <p
        className="mb-1"
        style={{
          color: '#797979',
          fontSize: '0.875rem',
          fontWeight: 400,
          fontStyle: 'Unilever Shilling'
        }}>
        Select Model
      </p>
      <select
        defaultValue={'ALL'}
        className="form-select"
        style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
        onChange={(e) => handleModelChange(e.target.value)}
        id="modelselectbox">
        <option value="ALL">ALL</option>

        {modelData?.map((model) => (
          <option key={model.model_id} value={model.model_id}>
            {model.model_name}
          </option>
        ))}
      </select>
    </div>
  );
  const renderCards = () => {
    if (KPIs) {
      return (
        <div className="mb-4">
          <div className="row g-1 d-flex custom-card-row" style={{ columnGap: '5px' }}>
            <div className="col card d-flex col-10 col-md-4 rounded-3 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">Avg. Execution Time</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['avg_execution_time'] !== null &&
                    parseFloat(KPIs['avg_execution_time']).toFixed(2) + 's'}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 rounded-3 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Users</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['no_of_users'] !== null && formatter.format(parseInt(KPIs['no_of_users']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 rounded-3 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">Total No. of Request</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['total_no_of_requests'] !== null &&
                    formatter.format(parseInt(KPIs['total_no_of_requests']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 rounded-3 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">Successful Responses</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['no_of_successful_responses'] !== null &&
                    formatter.format(parseInt(KPIs['no_of_successful_responses']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 rounded-3 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Tokens</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['no_of_tokens'] !== null &&
                    formatter.format(parseInt(KPIs['no_of_tokens']))}
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  if (errorMessage)
    if (errorMessage.message === 'UNAUTHORIZED') return <Dialog />;
    else {
      return <PlaceHolder message={parse(errorMessages.serverError)} />;
    }
  if (isLoading)
    return (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );
  return (
    <>
      <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <div className="mb-2 mb-md-0 float-start mx-2 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Application
          </p>
          <select
            defaultValue={'ALL'}
            className="form-select"
            style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
            onChange={(e) => handleAppChange(e.target.value)}>
            <option value="ALL">ALL</option>
            {applicationList?.map((data) => (
              <option key={data.application_id} value={data.application_id}>
                {data.application_name}
              </option>
            ))}
          </select>
        </div>
        {selectedApp && selectedApp !== 'ALL' ? renderModelSelection() : ''}
      </div>

      {renderCards()}
      <div className="row g-2 row-cols-1 row-cols-md-2 mb-3 text-center">
        {isGraphView ? renderGraphs() : renderTables()}
      </div>
    </>
  );
};

export default Dashboard;

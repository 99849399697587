import Modal from 'react-bootstrap/Modal';
const Dialog = () => {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <div
      className="modal show"
      style={{
        display: 'block',
       position:'initial',
      
      }}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Your Session has expired</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Please refresh your browser to restore the session</p>
          <div className="d-flex justify-content-end mt-3">
            <button style={{}} className="btn btn-primary uniBtnBlue" onClick={handleRefresh}>
              Refresh
            </button>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
};
export default Dialog;

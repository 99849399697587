import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAzureData } from '../context/AzureContext';
import { fetchPromptById } from '../service/apiServices';
import { errorMessages } from '../utilities/constants';
import PlaceHolder from './PlaceHolder';
import parse from 'html-react-parser';
import Dialog from './Dialog';
type PromptInfo = {
  id: string;
  prompt_id: string;
  app_name: string;
  llm_tested_with: string;
  description: string;
  category: string;
  creation_date: string;
  last_modified_date: string;
  usage_examples: string;
  author: string;
  version: string;
};
const PromptLibraryDetails = (props: any) => {
  const { apiToken } = useAzureData();
  const [promptInfo, setPromptInfo] = useState<PromptInfo[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<any>();
  useEffect(() => {
    const fetchPromptInfo = async (id: any) => {
      try {
        const response = await fetchPromptById(id, apiToken);
        setPromptInfo(response);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    fetchPromptInfo(props.promptID);
  }, [apiToken]);

  if (errorMessage)
    if (errorMessage.message === 'UNAUTHORIZED' || errorMessage === 'UNAUTHORIZED')
      return <Dialog />;
    else {
      return <PlaceHolder message={parse(errorMessages.serverError)} />;
    }
  else
    return (
      <>
        {promptInfo ? (
          <div className="card mt-4">
            <button
              type="button"
              style={{
                alignSelf: 'flex-end',
                width: 'fit-content',
                marginTop: '2%',
                marginRight: '2%'
              }}
              onClick={props.onBackClick}
              className=" mb-4 btn btn-primary uniBtnPurple">
              Back
            </button>
            <div>
              <Accordion defaultActiveKey={promptInfo[0].id}>
                {promptInfo.map((prompt) => (
                  <Accordion.Item eventKey={prompt.id} key={prompt.id}>
                    <Accordion.Header>
                      <b>
                        {prompt.app_name}- Version {prompt.version}
                      </b>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body library-details">
                        <div className="row text-left">
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              Application Name :
                            </h4>
                            <span> {prompt.app_name}</span>
                          </div>
                        </div>
                        <div className="row text-left">
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              LLM tested against :
                            </h4>
                            <span> {prompt.llm_tested_with}</span>
                          </div>
                        </div>
                        <div className="row text-left">
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              Category :
                            </h4>
                            <span> {prompt.category}</span>
                          </div>
                        </div>
                        <div className="row text-left">
                          <div className="col">
                            <hr />
                            <h4 style={{ fontWeight: '600' }}>Description</h4>
                            <p>{prompt.description}</p>

                            <hr />
                            <h4 style={{ fontWeight: '600' }}>Usage Example</h4>
                            <p>{prompt.usage_examples}</p>
                            <hr />
                          </div>
                        </div>
                        <div className="row mb-3 text-left">
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              Author :
                            </h4>
                            <span> {prompt.author}</span>
                          </div>
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              Creation Date :
                            </h4>
                            <span> {prompt.creation_date}</span>
                          </div>
                          <div className="col">
                            <h4 style={{ fontWeight: '600' }} className="d-inline-flex">
                              Last Modified Date :
                            </h4>
                            <span> {prompt.last_modified_date}</span>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        ) : (
          <div
            style={{ height: '100vh' }}
            className="container-fluid d-flex justify-content-center align-items-center">
            <h4 className="text-center">Loading...</h4>
          </div>
        )}
      </>
    );
};

export default PromptLibraryDetails;

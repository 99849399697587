import Card from 'react-bootstrap/Card';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
  ArcElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  dataForBarGraph,
  dataForLineGraph,
  dataForPieChart,
  optionsForBarGraph,
  optionsForLineGraph,
  optionsForPieChart
} from '../utilities/helper';
import './Graph.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
type GraphProps = {
  graphType: string;
  labelText: string;
  xLabel?:string;
  yLabel?:string;
  xData: any[];
  yData: any[];
  dataSetLabel?:string;
};

export const Graph = ({ graphType, labelText, xData,xLabel, yData,yLabel,dataSetLabel }: GraphProps) => {
  let graph;

  switch (graphType) {
    case 'line':
      graph = (
        <Line options={optionsForLineGraph(labelText,xLabel,yLabel)} data={dataForLineGraph(xData, yData,dataSetLabel)} />
      );
      break;

    case 'bar':
      graph = <Bar options={optionsForBarGraph(labelText)} data={dataForBarGraph(xData, yData)} />;
      break;
    case 'pie':
      graph = <Pie options={optionsForPieChart(labelText)} data={dataForPieChart(xData, yData)} />;
  }
  return (
    <Card className="my-3 rounded-5">
      <Card.Body style={{ height: '25rem' }}>{graph}</Card.Body>
    </Card>
  );
};

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css';

import { useEffect, useState } from 'react';
import logo from '../assets/img/logo.png';
import avatar from '../assets/img/avatar.svg';
import { useMsal } from '@azure/msal-react';
import { useAzureData } from '../context/AzureContext';
import { pages, RAGLink } from '../utilities/constants';

const Header = () => {
  const { instance } = useMsal();
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState<any>('dashboard');
  const { graphData } = useAzureData();
  const profileImage = sessionStorage.getItem('profileImageUrl');
  const { isAdmin } = useAzureData();
  const getRAGLink = () => {
    switch (window.location.hostname) {
      case 'localhost':
      case 'aiflmdev.unilever.com':
      case 'bnlwe-ai04-d-930633-webapi-02.azurewebsites.net':
        return RAGLink.dev;

      case 'aiflmuat.unilever.com':
      case 'bnlwe-ai03-q-931039-webapi-02.azurewebsites.net':
        return RAGLink.uat;

      case 'aiflmprod.unilever.com':
      case 'bnlwe-ai01-p-930632-webapi-02.azurewebsites.net':
        return RAGLink.prod;

      default:
        return RAGLink.dev;
    }
  };

  useEffect(() => {
    const [pageinfo] = pages.filter((page) => page.path.includes(pathname));
    if (pageinfo) {
      setActiveItem(pageinfo.groupname);
    } else {
      setActiveItem('');
    }
  }, [pathname]);
  const handleClick = (index: any) => {
    setActiveItem(index);
  };
  const openExternalLink = () => {
    const url = 'https://forms.office.com/e/L74SK3G79x';
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const logout = () => {
    try {
      const accounts = instance.getAllAccounts();
      const currentAccount = accounts[0];
      const logoutRequest = {
        account: currentAccount
      };
      instance.logoutRedirect(logoutRequest);
    } catch (err) {
      throw new Error('err');
    }
  };

  return (
    <>
      <Navbar expand="lg" className="uni-bg-blue uni-header" variant="dark">
        <Container fluid>
          <Navbar.Brand href="/" className="unl-div-logo">
            <img src={logo} height={89} width={89} alt={'unilver logo'} />
            <div className="uni-brand-text">
              <span className="uni-header-primary">AI Foundation</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="align-center">
            <Nav
              as={'ul'}
              className="me-auto  my-lg-0 list-container"
              style={{ gap: '10px', alignItems: 'center' }}
              navbarScroll>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={'/dashboard'}
                  className={`uni-nav-link ${activeItem === 'dashboard' ? 'active' : ''}`}
                  onClick={() => handleClick('dashboard')}>
                  Dashboard
                </Nav.Link>
              </Nav.Item>
              <NavDropdown
                title="Model and Responsible AI"
                className={`uni-nav-link ${activeItem === 'model' ? 'active' : ''}`}
                onClick={() => handleClick('model')}>
                <NavDropdown.Item as={Link} to={'/leaderboard'}>
                  Leaderboard
                </NavDropdown.Item>
                {isAdmin && (
                  <NavDropdown.Item as={Link} to={'/rai'}>
                    Responsible AI
                  </NavDropdown.Item>
                )}
              </NavDropdown>
              <NavDropdown
                className={`uni-nav-link ${activeItem === 'prompt' ? 'active' : ''}`}
                onClick={() => handleClick('prompt')}
                title="Prompt Builder">
                <NavDropdown.Item as={Link} to={'/promptlibrary'}>
                  Prompt library
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/promptplayground'}>
                  Prompt playground
                </NavDropdown.Item>
              </NavDropdown>
              {isAdmin && (
                <>
                  <Nav.Item className="uni-nav-link">
                    <Nav.Link
                      href={getRAGLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uni-nav-link">
                      RAG Builder
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={'/finops'}
                      className={`uni-nav-link ${activeItem === 'finops' ? 'active' : ''}`}
                      onClick={() => handleClick('finops')}>
                      FinOps
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
            <button className="btn uniBtnYellow" onClick={openExternalLink}>
              Start your AI journey
            </button>
            <NavDropdown
              className="user-profile uni-bg-blue"
              align="end"
              title={
                <>
                  <span style={{ marginRight: '5px' }}>{graphData?.['displayName']}</span>
                  <span>
                    <img
                      src={!profileImage ? avatar : profileImage}
                      alt="user icon"
                      className="user-img rounded-circle"
                    />
                  </span>
                </>
              }
              id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;

const PlaceHolder = (props:any) => {
  return (
    <div
      style={{ height: '50vh' }}
      className="container-fluid d-flex justify-content-center align-items-center">
      <h4 className="text-center" style={{ color: 'red' }}>{props.message}</h4>
    </div>
  );

};
export default PlaceHolder

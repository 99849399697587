import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { fetchAllPrompt, fetchApplicationsData } from '../service/apiServices';
import { useAzureData } from './AzureContext';
type PromptLibraryContextType = {
  applicationList: App[] | null;
  prompts: PromptInfo[] ;
  error: any;
  isLoading: boolean;
};
type PromptLibraryContextProviderProps = {
  children: ReactNode;
};
type App = {
  application_id: string;
  application_name: string;
};
type PromptInfo = {
  id: string;
  version: any;
  prompt_id: string;
  app_name: string;
  llm_tested_with: string;
  description: string;
  category: string;
  creation_date: string;
  last_modified_date: string;
  usage_examples: string;
  author: string;
};
export const PromptLibraryContext = createContext<PromptLibraryContextType | null>(null);
export const PromptLibraryContextProvider = ({ children }: PromptLibraryContextProviderProps) => {
  const [applicationList, setApplicationList] = useState<App[] | null>(null); //applications
  const [prompts, setPrompts] = useState<PromptInfo[] | []>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { apiToken } = useAzureData();
  const getPromptlibraryData = async () => {
    try {
      const applicationlist = await fetchApplicationsData(apiToken);
      setApplicationList(applicationlist);
      const listofprompts = await fetchAllPrompt(apiToken);
      setPrompts(listofprompts);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => getPromptlibraryData(), 1000);
    return () => clearTimeout(timer);
  }, []);
  const promptLibaryData = useMemo(
    () => ({ applicationList, prompts, error, isLoading }),
    [apiToken, isLoading]
  );
  return <PromptLibraryContext.Provider value={promptLibaryData}>{children}</PromptLibraryContext.Provider>;
};
export const usePromptLibraryContext = () => {
  const context = useContext(PromptLibraryContext);
  if (context === null) {
    throw new Error('usePromptLibraryContext must be used within a DataProvider');
  }
  return context;
};

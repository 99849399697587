import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useAzureData } from './AzureContext';
import {
  fetchRaiApplicationsData,
  fetchRaiKPIs,
  fetchRaiModelStatus
} from '../service/apiServices';
type RAIContextType = {
  applicationList: App[] | null;
  modelStatus: ModelStatus[];
  cardData: RAIKPIs | null;
  isLoading: boolean;
  error: any;
};

type RAIContextTypeProviderProps = {
  children: ReactNode;
};
type App = {
  application_id: number;
  application_name: string;
};
type RAIKPIs = {
  total_request_count: string;
  content_filter_count: string;
  hallucination_count: string;
  llm_judge_count: string;
  total_token_count: string;
  total_cost: string;
};
type ModelStatus = {
  application_id: number;
  application_name: string;
  model_name: string;
  model_id: number;
  content_filter: string;
  hallucination: string;
  llm_as_a_judge: string;
};
export const RAIContext = createContext<RAIContextType | null>(null);
export const RAIContextProvider = ({ children }: RAIContextTypeProviderProps) => {
  const [applicationList, setApplicationList] = useState<App[] | null>(null); //applications
  const [cardData, setCardData] = useState<RAIKPIs | null>(null);
  const [modelStatus, setModelStatus] = useState<ModelStatus[]>([]);

  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { apiToken, isAdmin } = useAzureData();
  const getRAIData = async () => {
    try {
      const applist = await fetchRaiApplicationsData(apiToken);
      setApplicationList(applist);
      const raikpis = await fetchRaiKPIs({ appId: undefined }, apiToken);
      setCardData(raikpis);
      const modelstatus = await fetchRaiModelStatus(apiToken);
      setModelStatus(modelstatus);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let timer = setTimeout(() => getRAIData(), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const raiData = useMemo(
    () => ({ applicationList, modelStatus, cardData, error, isLoading }),
    [apiToken, isLoading]
  );
  return <RAIContext.Provider value={raiData}>{children}</RAIContext.Provider>;
};

export const useRAIContext = (): RAIContextType => {
  const context = useContext(RAIContext);
  if (context === null) {
    throw new Error('useRAIContext must be used within a DataProvider');
  }
  return context;
};

import { ChartOptions } from "chart.js";
import { unileverColor } from "./constants";

const isallElementsNull=(arr:(number|string|null)[])=>{
  for (let element of arr) {
    if (element !== null) {
      return false;
    }
  }
  return true;
}

export const isValidArray=(arr:any) =>{
  return arr !== null && arr.length > 0 && !isallElementsNull(arr);
}

export  const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  notation: 'compact',
});
export const createHeaders = (token: any) => {
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type','application/json' )
    return headers;
  }
};
export const optionsForLineGraph = (text: string) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top' as const
        },
        title: {
          display: true,
          text
        }
      },
      scales: {
        x: {
          grid: {
            display: true,
            color: 'rgba(0,0,0,0.1)',
            borderDash: [2, 2]
          }
        },
        y: {
          grid: {
            display: true,
            color: 'rgba(0,0,0,0.1)',
            borderDash: [2, 2]
          }
        }
      }
    };
  };
  export const dataForLineGraph = (xDataSet: number[], yDataSet: number[]) => {
    return {
      labels: xDataSet,
      datasets: [
        {
          fill: true,
          label: 'Dataset 2',
          data: yDataSet,
          borderColor: unileverColor['blue'].rgb,
          backgroundColor: unileverColor['blue'].rgb,
        }
      ]
    };
  };
  
  export const optionsForBarGraph = (textVal: string): ChartOptions<'bar'> => {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: textVal,
        },
        legend: {
          display: true,
          position: 'right'
        },
      }
    };
  };
  
  const generateBarGraphData = (xDataSet:any) => {
    const colors = Object.values(unileverColor).map(color => color.rgb);
    const keys = Object.keys(xDataSet[0].values);
    return keys.map((key:any, index) => ({
      label: key.replace(/_/g, ' ').replace('/v1/score', ''),
      data: xDataSet.map((item:any) => item.values[key]),
      backgroundColor: colors[index % colors.length],
      borderColor: colors[index % colors.length],
      borderWidth: 1
    }))
  }
  export const dataForBarGraph = (xDataset: any[], yDataSet: any[]) => {
  return {
    labels:yDataSet,
    datasets:generateBarGraphData(xDataset)
  }
  
  };
  
  export const optionsForPieChart = (text: string): ChartOptions<'pie'> => {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
  
      plugins: {
        title: {
          display: true,
          text
        },
        legend: {
          position: 'right'
        }
      }
    };
  };
  
  export const dataForPieChart = (xDataSet: any[], yDataSet: string[]) => {
    const colors = Object.values(unileverColor).map(color => color.rgb)
    return {
      labels: yDataSet,
      datasets: [
        {
          fill: true,
          label: 'Percentage',
          data: xDataSet,
          backgroundColor: colors,
          borderwidth: 1
        }
      ]
    };
  };
  
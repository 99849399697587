import { graphConfig } from "../config/authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callMsProfile(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("responseType", 'blob');

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.profileImageEndpoint, options)
        .then(async response => {
           if(response.status!==404){
           const data = await response.blob();
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data);
            return blobUrl;
           }
        }).catch(error => console.log(error));
}

import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { loginRequest, msalConfig } from '../config/authConfig';
import { callMsGraph, callMsProfile } from '../components/MsGraph';
import { fetchAdminCheck } from '../service/apiServices';

type AzureContextProviderProps = {
  children: ReactNode;
};
type UserProfile = {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  userPrincipalName: string;
  jobTitle?: string; // Optional property
  officeLocation?: string; // Optional property
};
type AzureContextType = {
  graphData: UserProfile | null;
  token: string | undefined;
  apiToken: string | undefined;
  isLoading: boolean;
  error: Error | null;
  isAdmin: boolean | null;
};

export const AzureContext = createContext<AzureContextType | null>(null);

export const AzureContextProvider = ({ children }: AzureContextProviderProps) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [graphData, setGraphData] = useState<UserProfile | null>(null);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [apiToken, setApiToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<any>(null);

  const acquireAccessToken = async (account: AccountInfo) => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: account
    };

    const response = await instance.acquireTokenSilent(accessTokenRequest);
    setToken(response.accessToken);
    const graphData = await callMsGraph(response.accessToken);
    setGraphData(graphData);
    sessionStorage.removeItem("profileImageUrl");
    const profileImage = await callMsProfile(response.accessToken);
    if(profileImage){
      sessionStorage.setItem("profileImageUrl",profileImage);
    }
  };
  const acquireApiToken = async (account: AccountInfo) => {
    const apiAccessTokenRequest = {
      scopes: loginRequest.extraScopesToConsent,
      account: account
    };

    const response = await instance.acquireTokenSilent(apiAccessTokenRequest);
    setApiToken(response.accessToken);
    const adminCheckCondition = async () => {
      try {
        const result = await fetchAdminCheck(response.accessToken);
        if(result.is_admin === false && result.is_owner === false){
          setIsAdmin(false);
        }else{
          setIsAdmin(true);
        }
      } catch (error: any) {
        console.log(error);
      }
    };
    adminCheckCondition();
  };
  const getToken = async () => {
    try {
      const accounts = instance.getAllAccounts();
      const currentAccount = accounts[0];

      if (currentAccount && currentAccount.tenantId === msalConfig.auth.tenantId) {
        await acquireAccessToken(currentAccount);
        await acquireApiToken(currentAccount);
      }
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    async function mainLogin() {
      try {
        setIsLoading(true)
        await instance.loginRedirect(loginRequest);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      sessionStorage.setItem('redirectDone', '0');
      mainLogin();
    } else if (isAuthenticated) {
      getToken();
      if (
        !sessionStorage.getItem('redirectDone') ||
        sessionStorage.getItem('redirectDone') !== '1'
      ) {
        sessionStorage.setItem('redirectDone', '1');
        window.location.reload();
      }
    }
  }, [isAuthenticated, inProgress, instance]);
  const azureData = useMemo(
    () => ({ graphData, token, apiToken, error, isLoading, isAdmin }),
    [graphData, token, apiToken, error, isLoading, isAdmin]
  );
  return <AzureContext.Provider value={azureData}>{children}</AzureContext.Provider>;
};

export const useAzureData = (): AzureContextType => {
  const context = useContext(AzureContext);
  if (context === null) {
    throw new Error('useAzureData must be used within a DataProvider');
  }
  return context;
};

import { ReactNode } from "react"
import { DashboardContextProvider } from "./DashboardContext"
import { RAIContextProvider } from "./RAIContext"
import { LeaderboardContextProvider } from "./LeaderboardContext"
import { PromptLibraryContextProvider } from "./PromptLibraryContext"
import { PromptPlaygroundContextProvider } from "./PromptPlayGroundContext"

type ContextProviderProps={
    children:ReactNode
}
const ContextProvider=({children}:ContextProviderProps)=>{
return (
   
    
    <DashboardContextProvider>
        <RAIContextProvider>
            <LeaderboardContextProvider>
                <PromptLibraryContextProvider>
                    <PromptPlaygroundContextProvider>
                    {children}
                    </PromptPlaygroundContextProvider>
                
                </PromptLibraryContextProvider>
                
            </LeaderboardContextProvider>
        </RAIContextProvider>
    </DashboardContextProvider>
  
    
)
}
export default ContextProvider